.aside-left--fixed {
  position: fixed;
  background-color: #252524;

}

.left-header {
  color: #FFF !important;
  text-align: center;
  font-size: 26px;
}

.left-header .img {
  width: 20px !important;
}


.sidebar-animated {
  transition: all 0.2s ease-out;
}
.sidebar-menu__marginTop {
  margin: {
    top: 0px !important;
  }
}


.list-inline .btn {
  background-color: #3396E5 !important;
  
}

.sidebar-form {
  background-color: #fff !important;
  margin: 15px;
}

.sidebar-menu__group-container {
  margin: {
    top: 0px !important;
  }
  height: 20px;
}

.sidebar-menu__group-title {
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}

.left-side {
  z-index: 10;
  transform: translateX(0) !important; 
}
.left-side.collapse-left {
    transform: translateX(-220px) !important; 
}



@media screen and (max-width: 990px) {
  .aside-left {
    position: fixed;
  }

  .left-side {
    z-index: 100;
    margin-top: 56px;
    transform: translateX(220px) !important;
  }

  .left-side.collapse-left {
      transform: translateX(0px) !important;
  }
}
