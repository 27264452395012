.rs-picker-menu{
    z-index: 100000000000000000!important;
  }


.rs-btn-primary {
  background-color: #3396E5!important;
}

.rs-btn-selected {
  background-color: #16CAAF!important;
  color: #FFF!important;
}


.rs-btn-toggle-checked{
  background-color: #3396E5!important;
}


.custom-input-number {
  .rs-input-number-btn-group-vertical {
    display: none;
  }
}

.rs-table-scrollbar-vertical{
  width: 18px!important;
}
.rs-table-scrollbar-vertical .rs-table-scrollbar-handle{
  width: 15px!important;
}


.rs-table-scrollbar-horizontal{
  height: 18px!important;
}
.rs-table-scrollbar-horizontal .rs-table-scrollbar-handle{
  height: 15px!important;
}


.rs-tree{
  max-height: 2000px!important;
}



