

.fixed--header {
    position: fixed;
    background: #FFFFFF;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 1030;
    opacity: 0.9;

  }
  
  .header--left {
    float: left;
    vertical-align: middle;
    margin-top: 8px;
    margin-left: auto;
  }
  
  .header--right {
    float: right;
    vertical-align: middle;
    margin-top: 8px;
    margin-right: 10px;
  }
  
  .header a.logo img {
    margin: 3px;
    height: 48px !important;
  }
  
  
  .header--right {
    a {
      color: #000000 !important;
    }
  
  }
  
  @media screen and (max-width: 990px) {
    a.logo {
      width: 0px !important;
    }
  
    .navbar {
      margin: {
        left: 0px !important;
      }
      padding: {
        left: 0px !important;
      }
    }
  }
  