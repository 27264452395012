.right-side--top-margin {
  margin-top: 56px;
  position: relative;
  z-index: 1000;
}

.aside {
 
}

.aside-right-animated {
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
}

.right-side.strech {
    margin-left: 0 !important;
}

.right-side.ismobile{
  font-size: 0.6rem !important;
}




@media screen and (max-width: 990px) {
  .right-side {
    margin: {
      left: 0px;
    }
  }
}
