@import "./_vars.scss";
@import "./_mixins.scss";

.invisible {
  opacity: 0;
}

.view-enter {
  @include smooth-fade-in-up(0s, 0.5s, ease-in);
   z-index: 1;
 }

// fix form inline
.form-inline > * {
   margin:5px 3px;
}

// fix
.panel-heading {
  z-index: 1 !important;
}

// fix
.btn {
  margin: {
    left: 2px;
    right: 2px;
  }
}

// fix
.well {
    padding: 7px;
}

// fix
input[type="radio"],
input[type="checkbox"] {
  margin-right: 2px;
  margin-bottom: 0px;
  margin-left: 2px;
  margin-top: 2px;
}
