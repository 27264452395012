@mixin card-shadows {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}

// animations
@mixin smooth-fade-in-up($delay, $duration, $ease) {
  opacity: 0;
  animation-name: smoothFadeInUp;
  animation-timing-function: $ease;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-fill-mode: both;

  @keyframes smoothFadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 3%, 0);
    }

    90% {
      opacity: 0.3;
      transform: none;
    }

    95% {
      opacity: 0.5;
      transform: none;
    }

    100% {
      opacity: 1;
      transform: none;
    }
  }
}
