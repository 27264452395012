html,
body {
  overflow-x: hidden!important;
  font-family: Raleway, sans-serif;
  -webkit-font-smoothing: antialiased;
  /* min-height: 100%; */
  /* background: #3f3f3f ; */
  background: #fff ;
  /* overflow: hidden!important; */
  padding-right: 15px;
  height: 100%;
}

.defaultbutton {
  display: block;
  width: 100%;
  padding: 6px 12px;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  text-decoration: none;
  transition: all 0.3s;
}

.defaultbutton:hover {
  background-color: #f5f5f5;
}


.primarybutton {
  display: block;
  width: 100%;
  padding: 6px 12px;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: #0d6efd; /* primary color */
  border: 1px solid #0d6efd; /* primary color */
  border-radius: 4px;
  color: #fff; /* white text */
  text-decoration: none;
  transition: all 0.3s;
}

.primarybutton:hover {
  background-color: #0b5ed7; /* slightly darker primary color */
  border-color: #0b5ed7; /* slightly darker primary color */
}

